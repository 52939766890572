import Vue from 'vue';

// 레이아웃 변경을 위한 이벤트
export const EVENT_CHANGE_LAYOUT = 'EVENT_CHANGE_LAYOUT';

// 오류 발생 이벤트
export const EVENT_ERROR = 'EVENT_ERROR';

// 모달
export const EVENT_SHOW_MODAL = 'EVENT_SHOW_MODAL';
export const EVENT_SHOW_ALERT = 'EVENT_SHOW_ALERT';
export const EVENT_SHOW_CONFIRM = 'EVENT_SHOW_CONFIRM';
export const EVENT_CLOSE_MODAL = 'EVENT_CLOSE_MODAL';
export const EVENT_CLOSE_ALL_MODAL = 'EVENT_CLOSE_ALL_MODAL';

export const EVENT_SHOW_LOADING = 'EVENT_SHOW_LOADING';
export const EVENT_HIDE_LOADING = 'EVENT_HIDE_LOADING';

// 파일
export const EVENT_FILE_DOWNLOAD = 'EVENT_FILE_DOWNLOAD';
export const EVENT_TEMP_FILE_DOWNLOAD = 'EVENT_TEMP_FILE_DOWNLOAD';

/**
 * 걍 여기저기서 사용해야 하면 이걸 import해서 써도 됨
 * @type {Vue}
 */
export const $eventBus = new Vue();

/**
 * 모든 vue 오브젝트에서 this.$eventBus로 글로벌 이벤트 버스 접근 가능
 */
Object.defineProperties(Vue.prototype, {
    $eventBus: {
        get: function () {
            return $eventBus;
        },
    },
});

