import teacherApi from '@commonTeacher/apis/teacherApi';
import {getUrlClassNo} from "@common/utils/urlUtils";

export const TEACHER_USER_STORE_NAMESPACE = 'TEACHER_USER_STORE_NAMESPACE';

export const GET_CURRENT_CLASS = 'GET_CURRENT_CLASS';

const SET_LOAD = 'SET_LOAD';
const SET_PRIVILEGE = 'SET_PRIVILEGE';
const SET_SESSION = 'SET_SESSION';
const SET_SESSION_CHECK = 'SET_SESSION_CHECK';
const SET_ME = 'SET_ME';

export const ACTION_FETCH_ME = 'ACTION_FETCH_ME';
export const ACTION_FETCH_SESSION = 'ACTION_FETCH_SESSION';
export const ACTION_FETCH_SESSION_CHECK = 'ACTION_FETCH_SESSION_CHECK';
export const ACTION_SET_USER_LOAD = 'ACTION_SET_USER_LOAD';

export default {
    namespaced: true,

    state: {
        load: false,
        session: null,
        sessionCheck: null,
        me: null,
    },

    getters: {
        [GET_CURRENT_CLASS](state) {
            const classNo = getUrlClassNo();
            const classes = state.session.classes;
            let currentClass;

            if (classNo) {
                currentClass = classes.find(c => c.classNo === classNo);
            } else {
                currentClass = classes.find(c => c.state === 'NORMAL');
            }

            return currentClass;
        },
    },

    mutations: {
        [SET_LOAD](state, {load}) {
            state.load = load;
        },

        [SET_PRIVILEGE](state, {privilege}) {
            state.privilege = privilege;
        },

        [SET_SESSION](state, {session}) {
            state.session = session;
        },

        [SET_SESSION_CHECK](state, {sessionCheck}) {
            state.sessionCheck = sessionCheck;
        },

        [SET_ME](state, {me}) {
            state.me = me;
        },
    },

    actions: {
        async [ACTION_FETCH_SESSION]({commit}) {
            const session = await teacherApi.getSession();
            commit(SET_SESSION, {session});
        },

        async [ACTION_FETCH_SESSION_CHECK]({commit}) {
            const sessionCheck = await teacherApi.getSessionCheck();
            commit(SET_SESSION_CHECK, {sessionCheck});
        },

        async [ACTION_FETCH_ME]({commit}) {
            const me = await teacherApi.getMe();
            commit(SET_ME, {me});
        },

        [ACTION_SET_USER_LOAD]({commit}, {load}) {
            commit(SET_LOAD, {load});
        },
    },
};
