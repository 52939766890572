import {getQuery} from '@common/utils/urlUtils';

export let counselApi = null;
const COUNSEL_API_VERSION = process.env.VUE_APP_COUNSEL_API_VERSION;

export default {
	/**
	 * 각 서비스 beforeRouter 에서 주입
	 */
	setCounselApi({serviceApi}) {
		counselApi = serviceApi;
	},

	/**
	 * 상담 목록 조회
	 * @param schoolYear
	 * @param counselStatusTypeCodes
	 * @param counselTagNo
	 * @param tagKey
	 * @param searchKeyword
	 * @param offset
	 * @param limit
	 */
	getCounsels({counselStatusTypeCodes, counselTagNo, tagKey, searchKeyword, schoolYear, offset, limit} = {}) {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/counsels`, {
			params: {
				schoolYear,
				counselStatusTypeCodes: counselStatusTypeCodes.toString(),
				counselTagNo,
				tagKey,
				searchKeyword,
				offset,
				limit,
			},
		});
	},

	getCounselsByTargetUser({targetProviderTypeCode, targetUserId, classNo, schoolYear, referenceNo}) {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/target-counsels`, {
			params: {
				targetProviderTypeCode,
				targetUserId,
				classNo,
				schoolYear,
				referenceNo,
			},
		});
	},

	getUnreadCounselMessagesCount() {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/counsels/messages/read`);
	},

	/**
	 * 상담 상세조회 - 상담목록 조회의 아이엠 한 개(내용 차이 없음)
	 * @param counselNo
	 */
	getCounselDetail({counselNo}) {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/counsels/${counselNo}`);
	},

	/**
	 * 상담태그 목록 조회
	 */
	getCounselTags() {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/tags/read`);
	},

	/**
	 * 상담 생성
	 * @param providerTypeCode
	 * @param title
	 * @param schoolYear
	 * @param participants
	 * @param counselMessage
	 */
	registerCounsel({providerTypeCode, title, schoolYear, participants, counselMessage, referenceNo}) {
		return counselApi.post(`counsel/${COUNSEL_API_VERSION}/web/counsels`, {
			providerTypeCode,
			title,
			schoolYear,
			participants,
			counselMessage,
			referenceNo,
		});
	},

	/**
	 * 상담 종료
	 */
	completeCounsel({counselNo}) {
		return counselApi.put(`counsel/${COUNSEL_API_VERSION}/web/counsels/${counselNo}/complete`);
	},

	/**
	 * 상담 내용 조회
	 */
	getCounselMessages({counselNo, limit, oldestCounselMessageNo}) {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/counsels/${counselNo}/messages`, {
			params: {
				limit,
				lastStreamId: oldestCounselMessageNo,
			},
		});
	},

	/**
	 * 상담 내용 삭제
	 */
	deleteCounselMessage({counselNo, counselMessageNo}) {
		return counselApi.delete(`counsel/${COUNSEL_API_VERSION}/web/counsels/${counselNo}/messages/${counselMessageNo}`);
	},

	/**
	 * 상담 전송
	 * @param counselMessage
	 * @param counselMessage.content
	 * @param counselMessage.documents
	 * @param counselMessage.images
	 * @param counselMessage.mute 푸시 음소거 여부
	 * @param {CONTENT_TYPE} counselMessage.contentTypeCode
	 */
	sendCounselMessage({counselNo, counselMessage}) {
		return counselApi.post(`counsel/${COUNSEL_API_VERSION}/web/counsels/${counselNo}/messages`, {
			...counselMessage,
		});
	},

	/**
	 * 상담대상자 조회
	 */
	getCounsellable({providerType}) {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/counsels/counsellable`, {
			params: {
				providerTypeCode: providerType,
			},
		});
	},

	/**
	 * 상담메시지 읽음 처리
	 * @param counselNo
	 * @param latestCounselMessageNo
	 */
	readCounselMessages({counselNo, latestCounselMessageNo}) {
		if (getQuery().token) {
			return;
		}
		return counselApi.put(`counsel/${COUNSEL_API_VERSION}/web/counsels/${counselNo}/messages/${latestCounselMessageNo}/read`);
	},

	/**
	 * 마지막 읽은 상담메세지 번호 조회 (참여자 각각)
	 * @param counselNo
	 */
	getCounselLastReadMessages({counselNo}) {
		return counselApi.get(`counsel/${COUNSEL_API_VERSION}/web/counsels/${counselNo}/messages/last-read`);
	},
};
