import {EVENT_CLOSE_MODAL} from '@common/utils/eventUtil';
import {calcScrollbarWidth} from '@common/utils/scrollbarUtil';

let instances = [];

export default {
    name: 'Modal',

    props: {
        id: {
            type: Number,
            required: true,
        },

        onSuccess: {
            type: Function,
        },
    },

    data() {
        return {
            show: true,
            pending: false,
        };
    },

    mounted() {
        instances.push({vue: this, scrollY: window.scrollY});

        // overlay 값이 있는 모달은 곂쳐 보인다.
        if (!this.$options.overlay) {
            instances
                .filter(({vue}) => !vue.$options.overlay && vue !== this)
                .forEach(({vue}) => vue.show = false);
        }

        if (instances.length === 1) {
            // 스크롤 락
            const rect = document.body.getBoundingClientRect();
            const isBodyOverflowing = rect.left + rect.right < window.innerWidth;

            document.querySelector('html').style.overflow = 'hidden';
            document.body.classList.add('no_scroll');

            if (isBodyOverflowing) {
                document.body.style.marginRight = calcScrollbarWidth() + 'px';
            }
        }
    },

    destroyed() {
        const destroyedInstance = instances.find(({vue}) => vue === this);
        instances = instances.filter(({vue}) => vue !== this);

        if (instances.length) {
            // 다음 모달을 다시 보여준다.
            instances[instances.length - 1].vue.show = true;
        } else {
            // 스크롤 락 해제
            document.querySelector('html').style.overflow = 'visible';
            document.body.classList.remove('no_scroll');
            document.body.style.marginRight = '0';
            window.scrollTo(0, destroyedInstance.scrollY);
        }
    },

    methods: {
        async onClickOk(event) {
            event.preventDefault();
            await this.callClose(this.ok);
            await this.callOnSuccess();
        },

        async onClickCancel(event) {
            event.preventDefault();
            await this.callClose(this.cancel);
        },

        close() {
            this.$eventBus.$emit(EVENT_CLOSE_MODAL, {id: this.id});
        },

        async callClose(callback) {
            if (this.pending) {
                return;
            }

            if (!callback) {
                this.close();
                return;
            }

            const result = callback();
            if (result === false) {
                return;
            }

            if (result === undefined || result === true) {
                this.close();
                return;
            }

            this.pending = true;

            try {
                if (await Promise.resolve(result) === false) {
                    return;
                }
                this.close();
            } finally {
                this.pending = false;
            }
        },

        async callOnSuccess() {
            if (!this.onSuccess) {
                return;
            }

            await Promise.resolve(this.onSuccess());
        },
    },
};
