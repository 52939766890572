import SCHOOL_TYPE from '@common/consts/schoolConsts';
import i18n from '@common/i18n';
import {sprintf} from 'sprintf-js';

/**
 * 클래스 목록을 학년, 이름 순으로 정렬하는 함수
 *
 * @version 2020-01-23 신승엽 정렬용 값 생성 함수 분리, 미리 생성된 값을 사용 가능하면 사용하도록 함
 *
 * @param a
 * @param b
 * @returns {number}
 */
export function classSortFn(a, b) {
    let aValue = a.classSortValue;
    let bValue = b.classSortValue;

    if (!aValue) {
        aValue = makeClassSortValue({gradeType: a.gradeType, className: a.name || a.className});
    }

    if (!bValue) {
        bValue = makeClassSortValue({gradeType: b.gradeType, className: b.name || b.className});
    }

    if (aValue < bValue) {
        return -1;
    }

    if (aValue > bValue) {
        return 1;
    }

    return 0;
}

const numberOnlyClassSortFormat = '%02d_%010s';
const classSortFormat = '%02d_%s';

/**
 * 학급 정렬용 값 생성
 * @param gradeType
 * @param className
 * @returns {string}
 */
export function makeClassSortValue({gradeType, className}) {
    let format = classSortFormat;

    className = className.replace(/반$/, '');
    if (className.match(/^\d+$/)) {
        format = numberOnlyClassSortFormat;
    }

    return sprintf(format, gradeType.replace(/^[G|A]/, ''), className).toUpperCase();
}

/**
 * 학년 반 텍스트 반환
 * @param gradeType
 * @param className
 * @returns {string|*}
 */
export function getGradeAndClass({gradeType, className, schoolType}) {
    let gradeAndClass = '';
    if (gradeType) {
        gradeAndClass = getMiddleClassGradeText(gradeType, schoolType);
    }
    if (!className) {
        return gradeAndClass;
    }

    let middleText = ' ';

    return [gradeAndClass, classNameSuffix(className)].join(middleText).trim();
}

/**
 * 학년 텍스트 반환 (중간 길이, 1학년, 2학년...)
 * @param grade
 * @returns {VueI18n.TranslateResult}
 */
export function getMiddleClassGradeText(grade, schoolType) {
    if (schoolType === SCHOOL_TYPE.ETC) {
        return i18n.t(`grade.full.${grade}`);
    }

    return i18n.t(`grade.middle.${grade}`);
}

/**
 * 반 이름에서 마지막에 '반'을 붙인다. (한국 전용)
 * @param name
 * @returns {string|*}
 */
export function classNameSuffix(name) {
    if (!name) {
        return '';
    }

    if (name.match(/반$/)) {
        return name;
    }

    return `${name}반`;
}

