import Vue from 'vue';
import CommonError from '@common/errors/CommonError';
import {$modal} from '@common/utils/modalUtil';
import {openNormalPopup} from '@common/utils/popupUtil';
import {COUNSEL_SERVICE_ID} from '@common/consts/counselServiceConsts';

export const errorHandler = (err, vm, info) => {
	console.error(err);
	if (err instanceof CommonError) {
		if ((err.code === 401 || err.code === 999401)) {
			loginHandler(err);
		} else {
			if (err.customFn) {
				debugger;
				err.customFn();
			} else {
				$modal.alert({
					title: err.message,
					desc: err.desc,
					afterClose: err.afterClose,
				});
			}
		}
	} else {
		$modal.alert({
			title: '스크립트 오류가 발생하였습니다.',
			desc: err.message,
		});
	}
};

Vue.config.errorHandler = errorHandler;

function loginHandler(err) {
	console.debug('로그인 필요');

	if (window.serviceId === COUNSEL_SERVICE_ID.IAMTEACHERAPP) {
		$modal.alert({
			title: '로그인이 필요합니다.',
			ok: () => {
				document.location.replace(`${process.env.VUE_APP_AUTH_URL}/login?serviceId=iamteacher&channelId=web&nextUrl=${encodeURIComponent(document.location.href)}`);
			},
		});
		return;
	}

	console.debug('iamteacher pc 로그인 팝업');
	const currentDomain = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
	const nextUrl = `${currentDomain}/iamteacherpc/login-callback.html`;
	const loginUrl = getLoginUrl(nextUrl);

	$modal.alert({
		title: err.message,
		ok() {
			let popup;

			const timer = setInterval(() => {
				try {
					if (popup.closed) {
						clearInterval(timer);
						return;
					}

					const body = popup.document.getElementsByTagName('body')[0];
					body.style.minWidth = 'auto';
				} catch (e) {
					// no nothing
				}
			}, 100);

			popup = openNormalPopup(loginUrl, 'id', 500, 650);
		},
	});
}

function getLoginUrl(nextUrl) {
	return `${process.env.VUE_APP_AUTH_URL}/login?serviceId=iamteacher&channelId=web&nextUrl=${encodeURIComponent(nextUrl)}`;
}
