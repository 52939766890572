import qp from 'query-parse';

export function getQuery() {
    return qp.toObject(document.location.search.replace(/^\?/, ''));
}

export function getQueryFromUrl({url}) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    return qp.toObject(a.search.replace(/^\?/, ''));
}

export function getStringFromQuery({queryObj}) {
    return qp.toString(queryObj);
}

export function getUrlClassNo() {
    const classRegex = /\/class\/(\d+)\//;
    const matches = classRegex.exec(document.location.href);
    return matches && matches[1];
}

export function redirect(url) {
    window.location.href = url;
}

export function replace(url) {
    window.location.replace(url);
}
