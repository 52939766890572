import _loadScript from 'load-script';

export function loadScript(url) {
    return new Promise((resolve, reject) => {
        _loadScript(url, {async: false}, err => err ? reject() : resolve());
    });
}

export function loadCss(url) {
    const linkEl = document.createElement('link');
    linkEl.setAttribute('type', 'text/css');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.setAttribute('href', url);
    document.head.appendChild(linkEl);

    return new Promise((resolve, reject) => {
        linkEl.addEventListener('load', resolve);
        linkEl.addEventListener('error', reject);
    });
}
