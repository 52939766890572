import {COUNSEL_TIME_STATUS} from '@common/utils/counselTimeUtil.js';

/**
 * 상담 진행 상태
 * @type {{
 * PROGRESSING: string - 진행중,
 * COMPLETED: string - 완료,
 * BEFORE_CREATED: string - 시작전
 * }}
 */
export const COUNSEL_STATUS_TYPE = {
	PROGRESSING: 'PROGRESSING',
	COMPLETED: 'COMPLETED',
	BEFORE_CREATED: 'BEFORE_CREATED',
	STOP: 'STOP', // 상담 정지 처리, 프론트 동작은 COMPLETED와 동일함.
};

/**
 * 상담 참가자 PROVIDER_TYPE
 * @type {{IAMTEACHER: string, IAMSCHOOL: string}}
 */
export const PROVIDER_TYPE = {
	IAMTEACHER: 'IAMTEACHER',
	IAMSCHOOL: 'IAMSCHOOL',
	IAMSTUDENT: 'IAMSTUDENT',

	IAMSCHOOL_OR_IAMSTUDENT: ['IAMSCHOOL', 'IAMSTUDENT'], //선생님타입과 비교할 때 사용
};

/**
 * 상담 대상자 타입
 * @type {{STUDENT: string, PARENT: string}}
 */
export const PARTICIPANT_TYPE = {
	PARENT: 'PARENT',
	STUDENT: 'STUDENT',
};

export const PARTICIPANT_TEXT = {
	[PARTICIPANT_TYPE.PARENT]: '학부모',
	[PARTICIPANT_TYPE.STUDENT]: '학생',
};

/**
 * 상담 대상자 리스트 랜더링용도
 * @type {{MIX_LAST: string, MIX_FIRST: string, MIX: string, NORMAL: string}}
 */
export const WRAP_CLASS_NAME = {
	NORMAL: 'normal',
	MIX_FIRST: 'mix first',
	MIX: 'mix',
	MIX_LAST: 'mix last',
};

/**
 * 상담 내용 타입
 * @type {{FILE_ATTACH: string, TEXT: string, IMAGE_ATTACH: string}}
 */
export const CONTENT_TYPE = {
	TEXT: 'TEXT',
	FILE_ATTACH: 'FILE_ATTACH',
	IMAGE_ATTACH: 'IMAGE_ATTACH',
};

export const MAX_COUNSELS_COUNT_PER_ONE_REQUEST = 20;

export const MAX_COUNSEL_MESSAGES_COUNT_PER_ONE_REQUEST = 50;

export const COUNSEL_MESSAGE_TEXT_MAX_COUNT = 1000;

export const CHATROOM_STATUS = {
	NORMAL: 'NORMAL', //일반
	WITH_OUT_TIME: COUNSEL_TIME_STATUS.WITH_OUT_TIME, //선생님 상담가능 시간 밖
	ABSENCE: COUNSEL_TIME_STATUS.ABSENCE, //선생님 부재중
	TEACHER_COUNSEL_PAUSE: 'TEACHER_COUNSEL_PAUSE', //선생님 상담 사용안함

	COMPLETED: COUNSEL_STATUS_TYPE.COMPLETED, //완료처리된 상담
	NOT_AVAILABLE: 'NOT_AVAILABLE', //더이상 진행이 불가한 상담
	STOP: 'STOP', //반편성이 변경되어 정지된 상담
};
