import i18n from '@common/i18n';

/**
 * 현재 년도 반환
 * @returns {number}
 */
export function getThisYear() {
	return new Date().getFullYear();
}

export function getDayOfTheWeek() {
	const dayOfTheWeekObj = {};
	const dayOfTheWeekInverseObj = {};
	const dayOfTheWeekArray = [];

	i18n.t('common.date.dayOfTheWeek').split(',').forEach((weekName, index) => {
		dayOfTheWeekObj[index] = weekName;
		dayOfTheWeekInverseObj[weekName] = index;
		dayOfTheWeekArray.push(weekName);
	});

	return {
		dayOfTheWeekObj,
		dayOfTheWeekInverseObj,
		dayOfTheWeekArray,
	};
}

export function dateSortFn(a, b) {
	const aDate = new Date(a.sortedDate);
	const bDate = new Date(b.sortedDate);

	if (aDate < bDate) {
		return -1;
	}
	if (aDate > bDate) {
		return 1;
	}
	return 0;
}
