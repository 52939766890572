export default class CommonError extends Error {
    constructor({code = -1, message, desc, data, afterClose, customFn}) {
        super(message);
        this.code = code;
        this.desc = desc;
        this.data = data;
        this.afterClose = afterClose;
        this.customFn = customFn;
    }
}
