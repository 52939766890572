import counselApi from '@common/apis/counselApi.js';
import {CONTENT_TYPE, MAX_COUNSEL_MESSAGES_COUNT_PER_ONE_REQUEST} from '@common/consts/counselConsts.js';

export const CHATROOM_STORE_NAMESPACE = 'CHATROOM_STORE_NAMESPACE';
export const SET_CLEAR_CHATROOM_STORE = 'SET_CLEAR_CHATROOM_STORE';
export const SET_COUNSEL_MESSAGES = 'SET_COUNSEL_MESSAGES';
export const SET_REFRESH_COUNSEL_MESSAGES = 'SET_REFRESH_COUNSEL_MESSAGES';
export const SET_IS_LOAD_ALL_COUNSEL_MESSAGES = 'SET_IS_LOAD_ALL_COUNSEL_MESSAGES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_DELETE_COUNSEL_MESSAGE = 'SET_DELETE_COUNSEL_MESSAGE';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_IS_MUTE = 'SET_IS_MUTE';
export const SET_IS_AVAILABLE_READ = 'SET_IS_AVAILABLE_READ';
export const SET_IS_SHOW_ENTRANCE_MESSAGE = 'SET_IS_SHOW_ENTRANCE_MESSAGE';

export const ACTION_CLEAR_CHATROOM_STORE = 'ACTION_CLEAR_CHATROOM_STORE';

export const ACTION_LOAD_COUNSEL_MESSAGES = 'ACTION_LOAD_COUNSEL_MESSAGES';
export const ACTION_READ_COUNSEL_MESSAGES = 'ACTION_READ_COUNSEL_MESSAGES';
export const ACTION_REFRESH_COUNSEL_MESSAGES = 'ACTION_REFRESH_COUNSEL_MESSAGES';
export const ACTION_SEND_CONTENT_MESSAGE = 'ACTION_SEND_CONTENT_MESSAGE';
export const ACTION_SEND_DOCUMENTS_MESSAGE = 'ACTION_SEND_DOCUMENTS_MESSAGE';
export const ACTION_SEND_IMAGES_MESSAGE = 'ACTION_SEND_IMAGES_MESSAGE';
export const ACTION_DELETE_COUNSEL_MESSAGE = 'ACTION_DELETE_COUNSEL_MESSAGE';
export const ACTION_ADD_GUIDE_MESSAGE = 'ACTION_ADD_GUIDE_MESSAGE';

export const ACTION_CHANGE_CONTENT = 'ACTION_CHANGE_CONTENT';
export const ACTION_CHANGE_DOCUMENTS = 'ACTION_CHANGE_DOCUMENTS';
export const ACTION_CHANGE_IMAGES = 'ACTION_CHANGE_IMAGES';
export const ACTION_SET_IS_MUTE = 'ACTION_SET_IS_MUTE';
export const ACTION_SET_IS_AVAILABLE_READ = 'ACTION_SET_IS_AVAILABLE_READ';
export const ACTION_SET_IS_SHOW_ENTRANCE_MESSAGE = 'ACTION_SET_IS_SHOW_ENTRANCE_MESSAGE';

function getInitState() {
    return {
        counselMessages: [],
        isLoading: false,
        oldestCounselMessageNo: 0,
        isLoadAllCounselMessages: false,

        content: '',
        documents: [],
        images: [],
        isMute: false,
        isAvailableRead: true,
        isShowEntranceMessage: true,
    };
}

export default {
    namespaced: true,

    state: () => getInitState(),

    getters: {
        isBeforeLoadCounselMessages: state => state.oldestCounselMessageNo === 0,
    },

    mutations: {
        [SET_CLEAR_CHATROOM_STORE](state) {
            Object.entries(getInitState())
                  .forEach(([key, initValue]) => {
                      state[key] = initValue;
                  });
        },

        [SET_COUNSEL_MESSAGES](state, counselMessages) {
            state.counselMessages = counselMessages;
            state.oldestCounselMessageNo = counselMessages.length === 0 ? 0 : counselMessages[0].counselMessageNo;
        },

        [SET_IS_LOAD_ALL_COUNSEL_MESSAGES](state, isLoadAllCounselMessages) {
            state.isLoadAllCounselMessages = isLoadAllCounselMessages;
        },

        [SET_REFRESH_COUNSEL_MESSAGES](state) {
            state.oldestCounselMessageNo = 0;
            state.isLoadAllCounselMessages = false;
        },

        [SET_IS_LOADING](state, isLoading) {
            state.isLoading = isLoading;
        },

        [SET_DELETE_COUNSEL_MESSAGE](state, counselMessageNo) {
            const {counselMessages} = state;

            const deletedCounselMessageIndex = counselMessages.findIndex(counselMessage => counselMessage.counselMessageNo === counselMessageNo);
            counselMessages.splice(deletedCounselMessageIndex, 1, {
                ...counselMessages[deletedCounselMessageIndex],
                hideYn: 'Y',
                content: '',
                documents: null,
                images: null,
            });
        },

        [SET_CONTENT](state, content) {
            state.content = content;
        },

        [SET_DOCUMENTS](state, documents) {
            state.documents = documents;
        },

        [SET_IMAGES](state, images) {
            state.images = images;
        },

        [SET_IS_MUTE](state, isMute) {
            state.isMute = isMute;
        },

        [SET_IS_AVAILABLE_READ](state, isAvailableRead) {
            state.isAvailableRead = isAvailableRead;
        },
        [SET_IS_SHOW_ENTRANCE_MESSAGE](state, isShowEntranceMessage) {
            state.isShowEntranceMessage = isShowEntranceMessage;
        },
    },

    actions: {
        [ACTION_CLEAR_CHATROOM_STORE]({commit}) {
            commit(SET_CLEAR_CHATROOM_STORE);
        },

        async [ACTION_LOAD_COUNSEL_MESSAGES]({dispatch, state, commit}, counselNo) {
            const {counselMessages: originCounselMessages, oldestCounselMessageNo, isLoadAllCounselMessages} = state;

            if (isLoadAllCounselMessages) {
                return;
            }

            commit(SET_IS_LOADING, true);

            try {
                const {list: newCounselMessages = []} = await counselApi.getCounselMessages({
                    counselNo,
                    limit: MAX_COUNSEL_MESSAGES_COUNT_PER_ONE_REQUEST,
                    oldestCounselMessageNo,
                });

                if (newCounselMessages.length === 0) {
                    commit(SET_IS_LOAD_ALL_COUNSEL_MESSAGES, true);
                    return;
                }

                const afterCounselMessages = oldestCounselMessageNo === 0 ? newCounselMessages : [...newCounselMessages, ...originCounselMessages];
                commit(SET_COUNSEL_MESSAGES, afterCounselMessages);

                if (oldestCounselMessageNo === 0) {
                    dispatch(ACTION_READ_COUNSEL_MESSAGES, counselNo);
                }

            } finally {
                commit(SET_IS_LOADING, false);
            }
        },

        [ACTION_READ_COUNSEL_MESSAGES]({state}, counselNo) {
            if (!state.isAvailableRead) {
                return;
            }

            const {counselMessageNo: latestCounselMessageNo} = state.counselMessages
                                                                    .filter(({isGuideMessage}) => !isGuideMessage)
                                                                    .slice(-1)[0];

            //읽음처리는 결과를 기다려줄 필요 없음
            counselApi.readCounselMessages({
                counselNo,
                latestCounselMessageNo,
            });
        },

        async [ACTION_REFRESH_COUNSEL_MESSAGES]({commit}) {
            commit(SET_REFRESH_COUNSEL_MESSAGES);
        },

        async [ACTION_SEND_CONTENT_MESSAGE]({dispatch, commit, state}, counselNo) {
            const {content, isMute} = state;
            await counselApi.sendCounselMessage({
                counselNo,
                counselMessage: {
                    content: content.trim(),
                    contentTypeCode: CONTENT_TYPE.TEXT,
                    mute: isMute,
                },
            });
            commit(SET_IS_SHOW_ENTRANCE_MESSAGE, false);
            dispatch(ACTION_REFRESH_COUNSEL_MESSAGES, counselNo);
            dispatch(ACTION_CHANGE_CONTENT, '');
        },

        async [ACTION_SEND_DOCUMENTS_MESSAGE]({dispatch, commit, state}, counselNo) {
            const {documents, isMute} = state;
            await counselApi.sendCounselMessage({
                counselNo,
                counselMessage: {
                    documents: documents.map((document, index) => ({
                        documentNo: document.documentNo,
                        orderNo: index,
                    })),
                    contentTypeCode: CONTENT_TYPE.FILE_ATTACH,
                    mute: isMute,
                },
            });
            commit(SET_IS_SHOW_ENTRANCE_MESSAGE, false);
            dispatch(ACTION_REFRESH_COUNSEL_MESSAGES, counselNo);
            dispatch(ACTION_CHANGE_DOCUMENTS, []);
        },

        async [ACTION_SEND_IMAGES_MESSAGE]({dispatch, commit, state}, counselNo) {
            const {images, isMute} = state;
            await counselApi.sendCounselMessage({
                counselNo,
                counselMessage: {
                    images: images.map((image) => ({
                        type: 'IMAGE',
                        attachmentNo: image.imageNo,
                    })),
                    contentTypeCode: CONTENT_TYPE.IMAGE_ATTACH,
                    mute: isMute,
                },
            });
            commit(SET_IS_SHOW_ENTRANCE_MESSAGE, false);
            dispatch(ACTION_REFRESH_COUNSEL_MESSAGES, counselNo);
            dispatch(ACTION_CHANGE_IMAGES, []);
        },

        async [ACTION_DELETE_COUNSEL_MESSAGE]({commit}, {counselNo, counselMessageNo}) {
            await counselApi.deleteCounselMessage({
                counselNo,
                counselMessageNo,
            });

            commit(SET_DELETE_COUNSEL_MESSAGE, counselMessageNo);
        },

        /**
         * 실제 상대방이 보낸 메시지가 아닌 안내가이드 메시지
         * @param guideMessageContent
         */
        async [ACTION_ADD_GUIDE_MESSAGE]({commit, state}, guideMessageContent) {
            const guideCounselMessage = {
                content: guideMessageContent,
                contentTypeCode: CONTENT_TYPE.TEXT,
                counselMessageNo: -1,
                registerDate: new Date().toISOString(),
                isGuideMessage: true,
            };

            commit(SET_COUNSEL_MESSAGES, [...state.counselMessages, guideCounselMessage]);
        },

        [ACTION_CHANGE_CONTENT]({commit}, content) {
            commit(SET_CONTENT, content);
        },

        [ACTION_CHANGE_DOCUMENTS]({commit}, documents) {
            commit(SET_DOCUMENTS, documents);
        },

        [ACTION_CHANGE_IMAGES]({commit}, images) {
            commit(SET_IMAGES, images);
        },

        [ACTION_SET_IS_MUTE]({commit}, isMute) {
            commit(SET_IS_MUTE, isMute);
        },

        [ACTION_SET_IS_AVAILABLE_READ]({commit}, isAvailableRead) {
            commit(SET_IS_AVAILABLE_READ, isAvailableRead);
        },

        [ACTION_SET_IS_SHOW_ENTRANCE_MESSAGE]({commit}, isShowEntranceMessage) {
            commit(SET_IS_SHOW_ENTRANCE_MESSAGE, isShowEntranceMessage);
        },
    },
};
