import schoolStore from '@commonSchool/store/schoolStore.js';
import {ACTION_FETCH_ME, ACTION_SET_USER_LOAD} from '@commonSchool/store/schoolActions.js';
import {errorHandler} from '@commonSchool/errors/SchoolErrorHandle.js';
import {SCHOOL_USER_STORE_NAMESPACE} from '@commonSchool/store/modules/schoolUserStore.js';

export default async (to, from, next) => {
	if (schoolStore.state[SCHOOL_USER_STORE_NAMESPACE].load) {
		next();
		return;
	}

	try {
		await schoolStore.dispatch(ACTION_FETCH_ME);
		await schoolStore.dispatch(ACTION_SET_USER_LOAD, {load: true});
		next();
	} catch (e) {
		errorHandler(e);
	}

}
