<template>
    <output v-if="isKorean">
        <template v-for="(para, index) in splitText">
            <template v-for="(text, index2) in para">
                <component :is="tag">{{text}}{{
                        index2 < para.length - 1 ? interText : ''
                    }}
                </component>
                {{index2 < para.length - 1 ? ' ' : ''}}
            </template>
            <br v-if="index < splitText.length - 1">
        </template>
    </output>
    <output v-else>{{text}}</output>
</template>

<script>
    export default {
        name: 'HangulWordWrapText',

        props: {
            tag: {
                default: 'output',
            },

            text: {
                type: String,
                required: true,
            },

            splitRegex: {
                default: () => / +/,
            },

            interText: {
                default: () => ' ',
            },
        },

        computed: {
            splitText() {
                return this.text.split('\n').map(t => t.split(this.splitRegex));
            },

            isKorean() {
                return true;
            },
        },
    };
</script>

<style scoped>

</style>
