let scrollbarWidth;

export function calcScrollbarWidth() {
    if (scrollbarWidth) {
        return scrollbarWidth;
    }

    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.width = '100px';
    container.style.height = '100px';
    container.style.overflow = 'scroll';
    document.body.appendChild(container);

    const inner = document.createElement('table');
    inner.style.width = '100%';
    inner.style.height = '110px';
    container.appendChild(inner);

    scrollbarWidth = 100 - inner.offsetWidth;
    container.parentNode.removeChild(container);

    return scrollbarWidth;
}

