export const ANDROID = 'ANDROID';
export const IOS = 'IOS';
export const MOBILE_OS = 'MOBILE_OS';
export const NOT_MOBILE = 'NOT_MOBILE';

if (!window.navigator) {
    // 서버는 navigator 없으므로 폴리필
    window.navigator = {userAgent: ''};
}

const isMobileDevice = (/iphone|ipad|ipod|android/i.test(window.navigator.userAgent.toLowerCase()));

export const checkUserAgent = () => {
    if (isMobileDevice) {
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.search('android') > -1) {
            return ANDROID;
        } else if ((userAgent.search('iphone') > -1) || (userAgent.search('ipod') > -1) || (userAgent.search('ipad') > -1)) {
            return IOS;
        } else {
            return MOBILE_OS;
        }
    } else {
        return NOT_MOBILE;
    }
};

export const dichotomyMobileOrNot = () => {
    const userAgent = checkUserAgent();
    return userAgent !== NOT_MOBILE;
};

export const isMobile = () => {
    const userAgent = checkUserAgent();
    if (userAgent === NOT_MOBILE) {
        return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
    } else {
        return true;
    }
};

export const dichotomyAndroidOrNot = () => {
    const userAgent = checkUserAgent();
    return userAgent !== IOS;
};

export const getApiXClientOS = () => {
    const userAgent = checkUserAgent();
    if (userAgent === ANDROID) {
        return 'AOS';
    } else if (userAgent === IOS) {
        return 'IOS';
    } else {
        return 'WEB';
    }
}
