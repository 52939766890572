import {getDayOfTheWeek} from '@common/utils/dateUtil.js';
import {sprintf} from 'sprintf-js';

/**
 * @typedef CounselTime
 * @property {number[]} days
 * @property {number} startHour
 * @property {number} startMin
 * @property {number} endHour
 * @property {number} endMin
 * @property {boolean} isAbsence // 부재중 여부
 */

/**
 * 상담 가능시간 Default
 * @type CounselTime
 */
const DEFAULT_COUNSEL_TIME = {
	days: [1, 2, 3, 4, 5],
	startHour: 9,
	startMin: 0,
	endHour: 17,
	endMin: 0,
	isAbsence: false,
};

export const COUNSEL_TIME_STATUS = {
	WITH_IN_TIME: 'WITH_IN_TIME', //상담 가능시간 내
	WITH_OUT_TIME: 'WITH_OUT_TIME', //상담 가능시간 밖
	ABSENCE: 'ABSENCE', //부재중
};

/**
 * @return {CounselTime}
 */
export function decodeCounselTime(payload) {
	if (!payload) {
		return DEFAULT_COUNSEL_TIME;
	}

	const counselTime = JSON.parse(payload);
	return {
		...DEFAULT_COUNSEL_TIME,
		...counselTime,
	};
}

/**
 * 상담시간 제한 여부
 * @param {CounselTime} counselTime
 * @returns {boolean}
 */
export function isLimitCounselTime(counselTime) {
	const {days, startHour, startMin, endHour, endMin} = counselTime;
	const now = new Date();

	//요일
	if (!days.includes(now.getDay())) {
		return true;
	}

	//시간
	if (isAllDay(counselTime)) {
		return false;
	}
	const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMin);
	const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMin);
	return now < startTime || now > endTime;
}

/**
 * @param {CounselTime} counselTime
 * @return {boolean}
 */
export function isAllDay(counselTime) {
	const {startHour, startMin, endHour, endMin} = counselTime;
	return (startHour === 0 && startMin === 0 && endHour === 0 && endMin === 0);
}

/**
 * @param {CounselTime} counselTime
 * @return {string}
 */
export function availableCounselDayOfWeek(counselTime) {
	const {dayOfTheWeekInverseObj, dayOfTheWeekArray} = getDayOfTheWeek();
	const mondayFirstWeek = [...dayOfTheWeekArray.slice(1), dayOfTheWeekArray[0]];
	return mondayFirstWeek
		.filter(weekName => counselTime.days.includes(dayOfTheWeekInverseObj[weekName]))
		.join(', ');
}

/**
 * @param {CounselTime} counselTime
 * @return {string}
 */
export function availableCounselTime(counselTime) {
	if (isAllDay(counselTime)) {
		return '24시간';
	}

	const {startHour, startMin, endHour, endMin} = counselTime;
	return `${sprintf('%02d', startHour)}:${sprintf('%02d', startMin)} - ${sprintf('%02d', endHour)}:${sprintf('%02d', endMin)}`;
}

/**
 * @param {CounselTime} counselTime
 */
export function getCounselTimeStatus(counselTime) {
	if (counselTime.isAbsence) {
		return COUNSEL_TIME_STATUS.ABSENCE;
	}

	if (isLimitCounselTime(counselTime)) {
		return COUNSEL_TIME_STATUS.WITH_OUT_TIME;
	}

	return COUNSEL_TIME_STATUS.WITH_IN_TIME;
}
