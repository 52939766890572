import teacherStore from '@commonTeacher/store/teacherStore';
import {errorHandler} from '@commonTeacher/errors/TeacherError';
import {
    ACTION_FETCH_SESSION,
    ACTION_FETCH_SESSION_CHECK,
    TEACHER_USER_STORE_NAMESPACE,
} from '@commonTeacher/store/modules/teacherUserStore.js';
import {ACTION_FETCH_ME, ACTION_SET_USER_LOAD} from '@commonSchool/store/schoolActions.js';
import CommonError from '@common/errors/CommonError.js';

export default async (to, from, next) => {
    if (teacherStore.state[TEACHER_USER_STORE_NAMESPACE].load) {
        next();
        return;
    }

    try {
        await teacherStore.dispatch(`${TEACHER_USER_STORE_NAMESPACE}/${ACTION_FETCH_SESSION}`);
        const {isLogged} = teacherStore.state[TEACHER_USER_STORE_NAMESPACE].session;
        if (!isLogged) {
            throw new CommonError({
                code: 401,
                message: '로그인이 필요합니다.',
            });
        }

        await teacherStore.dispatch(`${TEACHER_USER_STORE_NAMESPACE}/${ACTION_FETCH_SESSION_CHECK}`);
        await teacherStore.dispatch(`${TEACHER_USER_STORE_NAMESPACE}/${ACTION_FETCH_ME}`);
        await teacherStore.dispatch(`${TEACHER_USER_STORE_NAMESPACE}/${ACTION_SET_USER_LOAD}`, {load: true});
        next();
    } catch (e) {
        errorHandler(e);
    }
}
