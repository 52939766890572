import Vue from 'vue';
import moment from 'moment';
import {getDayOfTheWeek} from '@common/utils/dateUtil.js';

Object.defineProperties(Vue.prototype, {
    $filters: {
        get: () => ({
            datetime: ({value, locale = 'ko', format}) => {
                return moment(value)
                    .locale(locale)
                    .format(format);
            },
            counselsDateTime: ({value, locale = 'ko'}) => {
                const registerDate = new Date(value)
                if (registerDate.toDateString() !== new Date().toDateString()) {
                    return `${registerDate.getMonth() + 1}월 ${registerDate.getDate()}일`;
                }

                return registerDate.toLocaleString(locale, {hour: 'numeric', minute: 'numeric', hour12: true});
            },
            chatroomDividedDate: ({value, locale = 'ko'}) => {
                const date = new Date(value);
                const {dayOfTheWeekObj} = getDayOfTheWeek();
                return `${date.toLocaleDateString()} ${dayOfTheWeekObj[date.getDay()]}요일`;
            },
            countText: ({value}) => {
                if (Number(value) > 99) {
                    return '99+';
                }
                return value;
            },
        }),
    },
});
