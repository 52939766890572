import Axios from 'axios';
import CommonError from '@common/errors/CommonError';
import {getQuery} from '@common/utils/urlUtils';

export const REQUEST_CANCEL_CODE = -999998;

export function createApi({withCredentials = true, baseURL, headers} = {}) {
    const api = Axios.create({
        baseURL,
	    headers,
        withCredentials,
    });

    api.interceptors.request.use(operationTokenInterceptor);
    api.interceptors.response.use(defaultErrorInterceptorSuccess, defaultErrorInterceptorFail);

    return api;
}

function operationTokenInterceptor(config) {
    const query = getQuery();

    if (query.token) {
        config.headers['X-OP-TOKEN'] = query.token;
    }

    return config;
}

function defaultErrorInterceptorSuccess(response) {
    const error = getError(response);

    if (error !== true) {
        return error;
    }

    return response.data.data;
}

function defaultErrorInterceptorFail(error) {
    if (Axios.isCancel(error)) {
        throw new CommonError({
            code: REQUEST_CANCEL_CODE,
            message: 'Cancel',
        });
    }

    if (error.response) {
        getError(error.response);
    } else {
        getUnknownError();
    }
}

function getUnknownError() {
    throw new CommonError({
        code: -999999,
        message: '알 수 없는 오류가 발생하였습니다.',
    });
}

function getError(response) {
    if (
        !response.data.header ||
        typeof response.data.header.isSuccessful !== 'boolean' ||
        typeof response.data.header.resultCode !== 'number' ||
        response.data.header.resultMessage === undefined
    ) {
        getUnknownError();
    }

    if (!response.data.header.isSuccessful) {
        if (response.data.header.resultCode === 401) {
            throw new CommonError({
                code: 401,
                message: '로그인이 필요합니다.',
            });
        }

        if (!response.data.header.resultMessage) {
            getUnknownError();
        } else {
            throw new CommonError({
                code: response.data.header.resultCode,
                message: response.data.header.resultMessage,
                data: response.data.data,
            });
        }

    }

    return true;
}
