import schoolStore from '@commonSchool/store/schoolStore.js';
import {ACTION_FETCH_CHILDREN} from '@commonSchool/store/schoolActions.js';
import {errorHandler} from '@commonSchool/errors/SchoolErrorHandle.js';

export default async (to, from, next) => {
    if (schoolStore.state.children) {
        next();
        return;
    }

    try {
        await schoolStore.dispatch(ACTION_FETCH_CHILDREN);
        next();
    } catch (e) {
        errorHandler(e);
    }

}
