import Vue from 'vue';
import {saveAs} from 'file-saver';
import {$eventBus, EVENT_FILE_DOWNLOAD, EVENT_TEMP_FILE_DOWNLOAD} from '@common/utils/eventUtil';

export const $file = {
	download({counselMessageNo, documentNo, imageNos, fileName}) {
		$eventBus.$emit(EVENT_FILE_DOWNLOAD, {counselMessageNo, documentNo, imageNos, fileName});
	},

	downloadTempFile({tokenKey, documentNo, fileName}) {
		$eventBus.$emit(EVENT_TEMP_FILE_DOWNLOAD, {tokenKey, documentNo, fileName});
	},
};

Object.defineProperties(Vue.prototype, {
	$file: {
		get() {
			return $file;
		},
	},
});

/**
 * 파일 확장자 반환
 * @param fileName
 * @returns {string}
 */
export function getExtension(fileName) {
	return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
}

/**
 * 파일명 말줄임처리
 * 12자 초과인경우만 해당. 'abcdefghijklmnopqrstuvwxyz.png' => 'abcdef...uvwxyz.png'
 * @param originFileName
 */
export function convertToShortFileName(originFileName) {
	const fileNameWithoutExtension = originFileName.slice(0, originFileName.lastIndexOf('.'));
	if (fileNameWithoutExtension.length <= 12) {
		return originFileName;
	}

	const firstString = fileNameWithoutExtension.slice(0, 6);
	const lastString = fileNameWithoutExtension.slice(-6);
	return `${firstString}...${lastString}.${getExtension(originFileName)}`;
}

/**
 * 파일 확장별 css class
 * @param fileName
 * @returns {string}
 */
export function getExtensionClassName(fileName) {
	let ext = getExtension(fileName);
	switch (ext) {
		case 'xls' :
		case 'xlsx' :
			return 'excel';
		case 'doc' :
		case 'docx' :
			return 'word';
		case 'ppt':
		case 'pptx':
			return 'ppt';
		case 'pdf':
			return 'pdf';
		case 'hwp':
			return 'hwp';
		case 'png':
			return 'png';
		case 'jpg':
		case 'jpeg':
			return 'jpg';
		case 'gif':
			return 'gif';
		default :
			return 'etc';
	}
}

/**
 * 읽기 좋은 파일 사이즈 텍스트
 * @param fileSize
 * @returns {string}
 */
export function getReadableSize(fileSize) {
	if (fileSize < 1024) {
		return '1KB';
	}

	if (fileSize < 1048576) {
		return `${(fileSize / 1024).toFixed(0)}KB`;
	}

	return `${(fileSize / 1048576).toFixed(0)}MB`;
}

export function makeAndDownloadTextFile({fileName, text}) {
	const blob = new Blob([text], {type: 'text/plain;charset=utf-8'});
	saveAs(blob, fileName);
}
