import {SCHOOL_USER_STORE_NAMESPACE} from '@commonSchool/store/modules/schoolUserStore.js';

export default {
	name: 'UserMixin',

	computed: {
		'$user'() {
			const {name, nation, id: schoolUserId} = this.$store.state[SCHOOL_USER_STORE_NAMESPACE].me;

			return {
				name,
				nation,
				schoolUserId,
			};
		}
	}
}
