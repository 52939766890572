import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: null,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ko',
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    window.lang = lang;
    return lang;
}

export function loadLanguageAsync(lang) {
    if (i18n.locale === lang) {
        console.debug('현재 언어와 동일');
        return Promise.resolve(setI18nLanguage(lang));
    }

    if (loadedLanguages.includes(lang)) {
        console.debug('이미 로드한 언어');
        return Promise.resolve(setI18nLanguage(lang));
    }

    return import(/* webpackChunkName: "lang-[request]" */ `@common/locales/${lang}.json`).then(messages => {
        console.debug('언어 파일 로드 완료');
        i18n.setLocaleMessage(lang, messages.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
    });
}

export default i18n;
