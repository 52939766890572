import {COUNSEL_SERVICE_ID} from '@common/consts/counselServiceConsts.js';

export default function () {
    function emitGaClickEvent(options) {
        if (!options.view_category && window.route) {
            options.view_category = findRouteMeta(window.route, meta => meta.pageTitle);
        }

        try {
            if (window.schoolAnalytics) {
                window.schoolAnalytics.clickEvent(options);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return {
        emitGaClickEvent,
    };
};

const gaIds = {
    [COUNSEL_SERVICE_ID.IAMSCHOOLAPP]: process.env.VUE_APP_NOTICE_PARENT_APP,
    [COUNSEL_SERVICE_ID.IAMSCHOOLPC]: process.env.VUE_APP_PORTAL_APP,
    [COUNSEL_SERVICE_ID.IAMSTUDENTAPP]: process.env.VUE_APP_NOTICE_STUDENT_APP,
};

export const emitGaPageView = async route => {
    if (![COUNSEL_SERVICE_ID.IAMSTUDENTAPP, COUNSEL_SERVICE_ID.IAMSCHOOLPC, COUNSEL_SERVICE_ID.IAMSCHOOLAPP].includes(window.serviceId)) {
        return;
    }

    let pageTitle = findRouteMeta(route, meta => meta.pageTitle);
    if (!pageTitle) {
        pageTitle = route.name || document.title;
    }
    const gaId = gaIds[window.serviceId];

    try {
        await window.schoolAnalytics.init(gaId);
    } finally {
        window.schoolAnalytics.pageView({page_title: pageTitle}, false);
    }
};

export function findRouteMeta(route, predicate) {
    return predicate(route.meta) || predicate(route.matched.find(r => predicate(r.meta))?.meta || {});
}