import {classSortFn, getGradeAndClass} from '@common/utils/classUtil';
import CLASS_STATE from '@common/consts/classStateConsts';
import {TEACHER_USER_STORE_NAMESPACE} from '@commonTeacher/store/modules/teacherUserStore.js';

export default {
    name: 'TeacherUserMixin',

    computed: {
        '$user'() {
            const {session, me} = this.$store.state[TEACHER_USER_STORE_NAMESPACE];
            return {
                school: session?.school,
                schoolNo: session?.school?.schoolNo,
                schoolName: session?.school?.name,
                schoolYear: session?.school?.schoolYear,
                schoolType: session?.school?.type,
                instituteNo: session?.school?.instituteNo,

                name: me?.name,
                phone: me?.phone,
                neoIdNo: me?.idNo,
                teacherType: me?.teacherType,

                normalClass: session?.classes.find(klass => klass.state === CLASS_STATE.NORMAL),
                managedClasses: session?.classes
                    .filter(klass => klass.state === 'NORMAL' || klass.state === 'SUB_NORMAL')
                    .sort((classA, classB) => {
                        if (classA.state === 'NORMAL') {
                            return -1;
                        }
                        if (classB.state === 'NORMAL') {
                            return 1;
                        }
                        return classSortFn(classA, classB);
                    })
                    .map(klass => {
                            return {
                                ...klass,
                                gradeAndClassName: getGradeAndClass({gradeType: klass.gradeType, className: klass.name, schoolType: session?.school?.type}),
                            };
                        },
                    ),
            };
        },
    },
};
