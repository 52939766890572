<template>
    <div>
        <template v-for="modal in modals">
            <component :is="modal.component" v-bind="modal" :key="modal.id"/>
        </template>
        <component v-show="isLoading" :is="loadingComponent"/>
    </div>
</template>

<script>
    import {
        EVENT_CLOSE_ALL_MODAL,
        EVENT_CLOSE_MODAL, EVENT_HIDE_LOADING,
        EVENT_SHOW_ALERT,
        EVENT_SHOW_CONFIRM, EVENT_SHOW_LOADING,
        EVENT_SHOW_MODAL,
    } from '@common/utils/eventUtil';
    
    export default {
        name: 'ModalWrapper',
        
        props: {
            alertConfirmComponent: {
                type: Object,
                required: true,
            },
            loadingComponent: {
                type: Object,
                required: true,
            },
        },
        
        data() {
            return {
                alertConfirm: this.alertConfirmComponent,
                mobileAlertConfirm: this.alertConfirmComponent,
                modals: [],
                modalId: 0,
                loadings: [],
            };
        },
        
        watch: {
            '$route'(newValue, prevValue) {
                // 첫 페이지 로드 시..
                if (!prevValue.name) {
                    return;
                }
        
                this.closeAllModal();
            },
    
            isLoading(isLoading) {
                document.querySelector('html').style.overflow = isLoading ? 'hidden' : 'visible';
            },
        },
    
        created() {
            this.$eventBus.$on(EVENT_SHOW_ALERT, this.showAlert);
            this.$eventBus.$on(EVENT_SHOW_CONFIRM, this.showConfirm);
            this.$eventBus.$on(EVENT_SHOW_MODAL, this.showModal);
            this.$eventBus.$on(EVENT_CLOSE_MODAL, this.closeModal);
            this.$eventBus.$on(EVENT_CLOSE_ALL_MODAL, this.closeAllModal);
            this.$eventBus.$on(EVENT_SHOW_LOADING, this.showLoading);
            this.$eventBus.$on(EVENT_HIDE_LOADING, this.hideLoading);
        },
    
        computed: {
            isLoading() {
                return this.loadings.length > 0;
            },
        },
    
        methods: {
            /**
             * alert 표시
             * @param title - 제목
             * @param desc - 설명
             * @param descHtml - 설명 HTML 여부
             * @param okText - 주 동작 버튼 텍스트
             * @param ok - 주 동작 버튼 클릭 시 콜백 함수
             * @param onSuccess - ok 함수가 성공 시 호출
             * @param afterClose - 닫힌 후 호출될 콜백 함수
             */
            showAlert({title, titleHtml, desc, descHtml, okText, ok, onSuccess, afterClose}) {
                this.showModal({
                    type: 'alert',
                    component: this.isMobile() ? this.mobileAlertConfirm : this.alertConfirm,
                    title,
                    titleHtml,
                    desc,
                    descHtml,
                    okText,
                    ok,
                    onSuccess,
                    afterClose,
                });
            },
            
            /**
             * confirm 표시
             * @param title - 제목
             * @param titleHtml - 제목 HTML 여부
             * @param desc - 설명
             * @param descHtml - 설명 HTML 여부
             * @param okText - 주 동작 버튼 텍스트
             * @param ok - 주 동작 버튼 클릭 시 콜백 함수
             * @param cancelText - 취소 버튼 텍스트
             * @param cancel - 취소 버큰 클릭 시 콜백 함수
             * @param onSuccess - ok() 함수 성공 시 호출
             * @param afterClose - 닫힌 후 호출될 콜백 함수
             * @param wrapperClass - 모달 Wrapper class list
             */
            showConfirm({title, titleHtml, desc, descHtml, okText, ok, cancelText, cancel, onSuccess, afterClose, wrapperClass}) {
                this.showModal({
                    type: 'confirm',
                    component: this.isMobile() ? this.mobileAlertConfirm : this.alertConfirm,
                    title,
                    titleHtml,
                    desc,
                    descHtml,
                    okText,
                    ok,
                    cancelText,
                    cancel,
                    onSuccess,
                    afterClose,
                    wrapperClass,
                });
            },
            
            /**
             * modal 표시
             * @param payload
             */
            showModal(payload) {
                const id = this.getNextModalId();
                
                this.modals.push({
                    ...payload,
                    id,
                });
                
                if (payload.afterOpen) {
                    payload.afterOpen(id);
                }
            },
            
            /**
             * modal 닫기
             * @param id - 닫으려는 modal id
             */
            async closeModal({id} = {}) {
                //파라미터로 전달된 modal id 없는 경우 가장 마지막 모달창 닫기
                if (id !== 0 && !id) {
                    id = this.modals[this.modals.length - 1].id;
                }
                
                const modal = this.modals.find(m => m.id === id);
                this.modals = this.modals.filter(m => m.id !== id);
                
                await this.$nextTick();
                
                if (modal.afterClose) {
                    modal.afterClose();
                }
            },
            
            closeAllModal() {
                this.modals = [];
            },
            
            /**
             * modal id 획득
             * @returns {number}
             */
            getNextModalId() {
                return this.modalId++;
            },
        
            isMobile() {
                return document.location.href.indexOf('/m/') > -1;
            },
        
            showLoading({key}) {
                this.loadings.push(key);
            },
        
            hideLoading({key}) {
                const findIndex = this.loadings.findIndex((loadingKey => loadingKey === key));
                this.loadings.splice(findIndex, 1);
            },
        },
    };
</script>

<style scoped>

</style>
