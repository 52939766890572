import Vue from 'vue';

import {
    $eventBus,
    EVENT_CLOSE_MODAL,
    EVENT_SHOW_ALERT,
    EVENT_SHOW_CONFIRM,
    EVENT_SHOW_MODAL,
} from '@common/utils/eventUtil';

export const $modal = {
    show(component, payload) {
        return new Promise(resolve => {
            $eventBus.$emit(EVENT_SHOW_MODAL, {component, ...payload, afterOpen: (id) => resolve(id)});
        });
    },
    alert({title, titleHtml, desc, descHtml, okText, ok, onSuccess, afterClose}) {
        $eventBus.$emit(EVENT_SHOW_ALERT, {title, titleHtml, desc, descHtml, okText, ok, onSuccess, afterClose});
    },
    confirm({title, titleHtml, desc, descHtml, okText, ok, cancelText, cancel, onSuccess, afterClose, wrapperClass}) {
        $eventBus.$emit(EVENT_SHOW_CONFIRM, {
            title,
            titleHtml,
            desc,
            descHtml,
            okText,
            ok,
            cancelText,
            cancel,
            onSuccess,
            afterClose,
            wrapperClass,
        });
    },
    confirmPromise({title, titleHtml, desc, descHtml, okText, ok, cancelText, cancel, onSuccess, afterClose, wrapperClass}) {
        return new Promise(resolve => {
            this.confirm({
                title,
                titleHtml,
                desc,
                descHtml,
                okText,
                ok: () => resolve(true),
                cancelText,
                cancel: () => resolve(false),
                onSuccess,
                afterClose,
                wrapperClass,
            });
        });
    },
    close(id) {
        $eventBus.$emit(EVENT_CLOSE_MODAL, {id: id});
    },
};

Object.defineProperties(Vue.prototype, {
    $modal: {
        get() {
            return $modal;
        },
    },
});
