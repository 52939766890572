export function openNormalPopup(url, id, width, height) {
    const screenWidth = screen.availWidth;
    const screenHeight = screen.availHeight;
    const top = screenHeight / 2 - height / 2;
    const left = screenWidth / 2 - width / 2;

    const popup = window.open(url, id, `toolbar=no,menubar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=no,width=${width},height=${height},top=${top},left=${left}`);

    let safeCount = 0;
    const intervalId = setInterval(() => {
        if (safeCount++ > 100) {
            clearInterval(intervalId);
            return;
        }

        try {
            const innerWidth = popup.innerWidth;
            const innerHeight = popup.innerHeight;
            const outerWidth = popup.outerWidth;
            const outerHeight = popup.outerHeight;

            if (!innerWidth || !innerHeight || !outerWidth || !outerHeight) {
                return;
            }

            const diffWidth = outerWidth - innerWidth;
            const diffHeight = outerHeight - innerHeight;

            popup.resizeTo(width + diffWidth, height + diffHeight);
            clearInterval(intervalId);
        } catch (e) {
            // do nothing
        }
    }, 100);

    return popup;
}
