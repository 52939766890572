import Vue from 'vue';
import CommonError from '@common/errors/CommonError.js';
import {$modal} from '@common/utils/modalUtil.js';
import {COUNSEL_SERVICE_ID} from '@common/consts/counselServiceConsts.js';

export const errorHandler = (err, vm, info) => {
    console.error(err);
    if (err instanceof CommonError) {
        if ((err.code === 401 || err.code === 403 || err.code === 999401)) {
            loginHandler();
            return;
        } else {
            if (err.customFn) {
                err.customFn();
            } else {
                $modal.alert({
                    title: err.message,
                    desc: err.desc,
                    afterClose: err.afterClose,
                });
            }
        }
    } else {
        $modal.alert({
            title: '스크립트 오류가 발생하였습니다.',
            desc: err.message,
        });
    }
};

const authServiceIdMap = {
    [COUNSEL_SERVICE_ID.IAMSCHOOLPC]: 'iamschool',
    [COUNSEL_SERVICE_ID.IAMSCHOOLAPP]: 'iamschool',
    [COUNSEL_SERVICE_ID.IAMSTUDENTAPP]: 'iamstudent',
};

function loginHandler() {
    const authServiceId = authServiceIdMap[window.serviceId] || 'iambridge';

    $modal.alert({
        title: '로그인이 필요합니다.',
        ok: () => {
            document.location.replace(`${process.env.VUE_APP_AUTH_URL}/login?serviceId=${authServiceId}&channelId=web&nextUrl=${encodeURIComponent(document.location.href)}`);
        },
    });
}

Vue.config.errorHandler = errorHandler;

