import {createApi} from '@common/apis/commonApi';
import {getApiXClientOS} from "@common/utils/checkMobileDevice";

const teacherApi = createApi({
	baseURL: `${process.env.VUE_APP_EDGE_URL}/iamteacher`,
	headers: {
		'X-VERSION': '1',
		'X-CLIENT-OS': getApiXClientOS(),
		'X-CLIENT-TYPE': 'IAMTEACHER',
	},
});

export default {
	getSession() {
		return teacherApi.get('web/session');
	},

	getSessionCheck() {
		return teacherApi.get('web/session-check');
	},

	getMe() {
		return teacherApi.get('web/me');
	},

	/**
	 * 나의 상담 가능 시간 조회
	 */
	getCounselTime() {
		return teacherApi.get('/web/counsels/time');
	},

	/**
	 * 나의 상담 가능 시간 수정
	 * @param CounselTime
	 */
	changeCounselTime(counselTime) {
		return teacherApi.post('/web/counsels/time', counselTime);
	},

	/**
	 * 선생님 상담 사용 여부
	 */
	getTeacherCounselPause() {
		return teacherApi.get('/web/counsels/pause');
	},

	/**
	 * 선생님 상담 사용 여부 변경
	 * @param {boolean} isTeacherCounselPause
	 */
	changeTeacherCounselPause(isTeacherCounselPause) {
		return teacherApi.post('/web/counsels/pause', {isPause: isTeacherCounselPause});
	},

	/**
	 * 학급 학생 목록 조회
	 */
	getStudents({classNo}) {
		return teacherApi.get(`/web/classes/${classNo}/students`);
	},

	/**
	 * 상담 설정 조회
	 */
	getCounselSettings() {
		return teacherApi.get('/web/counsels/settings');
	},

	/**
	 * 상담 설정 저장
	 * @param counselSettings
	 */
	changeCounselSettings(counselSettings) {
		return teacherApi.post('/web/counsels/settings', counselSettings);
	},
};
