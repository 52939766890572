<template>
    <FocusLock :return-focus="true" v-show="show">
        <slot></slot>
    </FocusLock>
</template>

<script>
    import FocusLock from 'vue-focus-lock';
    
    export default {
        name: 'Modal',
        components: {FocusLock},
        
        computed: {
            show() {
                return this.$parent.show;
            },
        },
    };
</script>

<style scoped>

</style>
