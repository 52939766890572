import Vue from 'vue';
import Vuex from 'vuex';
import teacherUserStore, {TEACHER_USER_STORE_NAMESPACE} from '@commonTeacher/store/modules/teacherUserStore';
import chatroomStore, {CHATROOM_STORE_NAMESPACE} from '@common/features/chatroom/store/chatroomStore';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        [TEACHER_USER_STORE_NAMESPACE]: teacherUserStore,
        [CHATROOM_STORE_NAMESPACE]: chatroomStore,
    },
});
