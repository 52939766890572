import Axios from 'axios';
import CommonError from '@common/errors/CommonError.js';

const schoolApi = Axios.create({
	baseURL: `${process.env.VUE_APP_EDGE_URL}/iamschool`,
	withCredentials: true,
});

schoolApi.interceptors.response.use((response) => {
	return response.data;
}, function (error) {
	if (error.response.status === 403) {
		if (error.response?.data?.code === 'NOT_LOGIN') {
			throw new CommonError({
				message: '로그인이 필요합니다.',
				code: 403,
			});
		}
	}

	if (error.response?.data?.messages?.[0]) {
		throw new CommonError({
			message: error.response.data.messages[0],
		});
	}

	console.log(error);
	throw new CommonError({
		message: '알 수 없는 오류가 발생했습니다.',
	});
});

export default {
	getMe() {
		return schoolApi.get('id/1.0/users/iamschool');
	},

	getChildren() {
		return schoolApi.get('api/v5.0/children');
	},
};


