<template>
    <!--@formatter:off-->
    <div style="display: none;">
        <form ref="downloadForm" :action="downloadForm.action" :method="downloadForm.method"
              target="_download_frame"
              enctype="application/x-www-form-urlencoded"></form>
        <iframe width="0" height="0" style="width:0;height:0;border:0"
                name="_download_frame"></iframe>
    </div>
    <!--@formatter:on-->
</template>

<script>
import {EVENT_FILE_DOWNLOAD, EVENT_TEMP_FILE_DOWNLOAD} from '@common/utils/eventUtil';
import multimediaApi from '@common/apis/multimediaApi';
import buildUrl from 'build-url';
import {counselApi} from '@common/apis/counselApi';
import {isMobile} from '@common/utils/checkMobileDevice';
import {downloadScheme} from '@common/utils/webview';

export default {
    name: 'DocumentDownloadWrapper',

    data() {
        return {
            downloadForm: {
                action: '',
                method: '',
            },
        };
    },

    methods: {
        async fileDownload({counselMessageNo, documentNo, imageNos, fileName}) {
            const {serverUrl, token} = await multimediaApi.getDownloadToken({
                counselMessageNo,
                documentNo,
                imageNos,
            });

            const url = `${serverUrl}/download/${documentNo ? 'document' : 'media'}`;
            const action = buildUrl(url, {
                queryParams: {
                    token,
                    serviceCode: 'EDUCOMMON',
                    documentNos: documentNo,
                    imageNos,
                    fileName,
                },
            });
            this.download({action, method: 'post', fileName});
        },

        async tempFileDownload({tokenKey, documentNo, fileName}) {
            const action = `${counselApi.defaults.baseURL}/educommon/api/external/documents/temporary/${documentNo}/${tokenKey}/download`;
            this.download({action, method: 'get', fileName});
        },

        download({action, method, fileName}) {
            if (isMobile()) {
                downloadScheme({downloadUrl: action, fileName});
                return;
            }
            this.submitForm({action, method});
        },

        submitForm({action, method}) {
            this.downloadForm.action = action;
            this.downloadForm.method = method;
            this.$nextTick(() => this.$refs.downloadForm.submit());
        },
    },

    created() {
        this.$eventBus.$on(EVENT_FILE_DOWNLOAD, this.fileDownload);
        this.$eventBus.$on(EVENT_TEMP_FILE_DOWNLOAD, this.tempFileDownload);
    },
};
</script>

<style scoped>

</style>
