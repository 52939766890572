import {COUNSEL_SERVICE_ID} from '@common/consts/counselServiceConsts.js';
import {checkUserAgent} from '@common/utils/checkMobileDevice.js';

const isReal = process.env.VUE_APP_PROFILE === 'real';

export const schema = (schema, url) => {
	callScheme(`${schema}://${url}`);
};

export const closeScheme = () => {
	schema('webview', 'close');
};

export const downloadScheme = ({downloadUrl, fileName}) => {
	schema('webview', `download?url=${encodeURIComponent(downloadUrl)}&filename=${encodeURIComponent(fileName)}`);
};

export const toast = (schema, message) => {
	callScheme(`${schema}://toast?message=${encodeURIComponent(message)}`);
};

export const bottomSheetScheme = ({getFuncName, resultFuncName, id}) => {
	schema('webview', `showSelectBottomSheet?getFunc=${getFuncName}&resultFunc=${resultFuncName}&id=${id}`);
};

export const showImageViewerScheme = ({getFuncName, id}) => {
	schema('webview', `showImageViewer?getFunc=${getFuncName}&id=${id}`);
};

export const changeUnreadCounselMessageCountScheme = (unreadCounselMessagesCount) => {
	schema('webview', `chat/change-unread-count?count=${unreadCounselMessagesCount}`);
};

export const webviewScheme = (url) => {
	const landingType = 1;
	const webviewType = 4;
	callScheme(`${getProtocol()}://webview?landingtype=${landingType}&webviewtype=${webviewType}&url=${encodeURIComponent(url)}&webkittype=1`);
};

export const openOutBrowserScheme = (url) => {
	const landingType = 2;
	if (checkUserAgent() === 'IOS') {
		callScheme(`${getProtocol()}://webview?landingtype=${landingType}&url=${encodeURIComponent(url)}&safariapp=1`);
		return;
	}
	callScheme(`${getProtocol()}://webview?landingtype=${landingType}&url=${encodeURIComponent(url)}`);
};

function callScheme(url) {
	if (!isReal) {
		console.info(url);
	}

	let iframe = document.createElement('iframe');
	iframe.setAttribute('src', url);
	iframe.setAttribute('height', '1px');
	iframe.setAttribute('width', '1px');
	document.documentElement.appendChild(iframe);
	iframe.parentNode.removeChild(iframe);
	iframe = null;
}

function getProtocol() {
	if (window.serviceId === COUNSEL_SERVICE_ID.IAMSCHOOLAPP) {
		return 'iamschoolparentapp';
	}
	if (window.serviceId === COUNSEL_SERVICE_ID.IAMSTUDENTAPP) {
		return 'iamstudentapp';
	}
	if (window.serviceId === COUNSEL_SERVICE_ID.IAMTEACHERAPP) {
		return 'iamteacherapp';
	}
}
