export const VIEW_CATEGORY = {
    TEACHER_LIST: '선생님목록',
    COUNSEL_LIST: '상담목록',
    COUNSEL_CHATROOM: '상담대화창',
    MESSAGE_OPTION: '메세지옵션',
};

export const VIEW_AREA = {
    SELECT_YEAR: '학년도선택클릭',
    SELECT_CHILD: '자녀선택클릭',

    CHECK_AVAILABLE_TEACHER: '상담가능선생님만클릭',

    CLICK_TEACHER: '개별선생님상담하기클릭',
    CLICK_CHATROOM: '상담대화창클릭',
    CLICK_HERE_BUTTON: '여기버튼클릭',

    REFRESH: '새로고침버튼클릭',
    ATTACH_IMAGE: '사진첨부버튼클릭',
    ATTACH_FILE: '파일첨부버튼클릭',
    CLICK_TEXT_AREA: '텍스트필드클릭',
    ATTACHMENT: '첨부파일(사진,파일)버튼클릭',
    SEND: '전송버튼클릭',
    HERE_BUTTON: '여기버튼클릭',

    OPTION_DELETE_MESSAGE: '메시지삭제클릭',
    OPTION_COPY_MESSAGE: '내용복사클릭',
    OPTION_DOWNLOAD_ALL_IMAGE: '이미지한번에다운로드클릭',
};