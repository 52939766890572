import {getQuery, getStringFromQuery, replace} from '@common/utils/urlUtils.js';
import {CHILD_ID_ALL} from '@commonSchool/consts/childConsts.js';

const NOT_NEED_CHILD_ID_COMPONENT_NAMES = ['Chatroom', 'NewChatroom', 'CompletedCounsels'];

/**
 * @param to
 * @param from
 * @param next
 */
export default async (to, from, next) => {
    if (NOT_NEED_CHILD_ID_COMPONENT_NAMES.includes(to.name)) {
        next();
        return;
    }

    const {childId, organizationId} = getQuery();

    if (childId) {
        next();
        return;
    }

    const queryString = '?' + getStringFromQuery({
        queryObj: {
            ...to.query,
            childId: CHILD_ID_ALL,
            organizationId,
        },
    });
    replace(`/${window.serviceId.toLowerCase()}${to.path}${queryString}`);
}
