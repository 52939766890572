export default {
	name: 'AlertConfirmMixin',

	props: {
		id: {
			type: Number,
			required: true,
		},

		type: {
			type: String,
			required: true,
		},

		title: {
			type: String,
		},

		titleHtml: {
			type: Boolean,
			default: false,
		},

		desc: {
			type: String,
		},

		descHtml: {
			type: Boolean,
			default: false,
		},

		okText: {
			type: String,
			default() {
				return '확인';
			},
		},

		ok: {
			type: Function,
		},

		cancelText: {
			type: String,
			default() {
				return '취소';
			},
		},

		cancel: {
			type: Function,
		},

		wrapperClass: {
			type: Array,
			default() {
				return [];
			},
		},
	},

	computed: {
		isConfirm() {
			return this.type === 'confirm';
		},
	},
};
