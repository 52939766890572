import Vue from 'vue';
import Vuex from 'vuex';
import schoolUserStore, {SCHOOL_USER_STORE_NAMESPACE} from '@commonSchool/store/modules/schoolUserStore.js';
import chatroomStore, {CHATROOM_STORE_NAMESPACE} from '@common/features/chatroom/store/chatroomStore';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        [SCHOOL_USER_STORE_NAMESPACE]: schoolUserStore,
        [CHATROOM_STORE_NAMESPACE]: chatroomStore,
    },
});
