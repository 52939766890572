import counselApi from '@common/apis/counselApi';
import {createApi} from '@common/apis/commonApi';
import {COUNSEL_SERVICE_ID} from '@common/consts/counselServiceConsts';
import {getApiXClientOS} from "@common/utils/checkMobileDevice";

const COUNSEL_API_CONFIGS = {
	[COUNSEL_SERVICE_ID.IAMTEACHERPC]: {
		baseURL: `${process.env.VUE_APP_EDGE_URL}/iamteacher/counsel`,
		headers: {
			'X-VERSION': '1',
			'X-CLIENT-OS': getApiXClientOS(),
			'X-CLIENT-TYPE': 'IAMTEACHER',
		},
	},
	[COUNSEL_SERVICE_ID.IAMTEACHERAPP]: {
		baseURL: `${process.env.VUE_APP_EDGE_URL}/iamteacher/counsel`,
		headers: {
			'X-VERSION': '1',
			'X-CLIENT-OS': getApiXClientOS(),
			'X-CLIENT-TYPE': 'IAMTEACHER',
		},
	},
	[COUNSEL_SERVICE_ID.IAMSCHOOLPC]: {
		baseURL: `${process.env.VUE_APP_EDGE_URL}/iamschool/counsel`,
	},
	[COUNSEL_SERVICE_ID.IAMSCHOOLAPP]: {
		baseURL: `${process.env.VUE_APP_EDGE_URL}/iamschool/counsel`,
	},
	[COUNSEL_SERVICE_ID.IAMSTUDENTAPP]: {
		baseURL: `${process.env.VUE_APP_EDGE_URL}/iamschool/counsel`,
	},
};

counselApi.setCounselApi({
	serviceApi: createApi(COUNSEL_API_CONFIGS[window.serviceId]),
});


