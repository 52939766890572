import Axios from 'axios';
import {counselApi} from '@common/apis/counselApi';

const UPLOAD_SERVICE_CODE = 'EDUCOMMON';
const UPLOAD_CONTAINER_NAME = 'COUNSEL_DOCUMENT';
const UPLOAD_IMAGE_COUNSEL_REFERENCE_SERVICE_CODE = '100635';

export default {
    /**
     * 업로드 토큰 조회
     */
    getUploadToken() {
        return counselApi.get('educommon/api/multimedia/token', {
            params: {
                tokenType: 'UPLOAD',
                '_': new Date().getTime(), //IE 에서 토큰이 캐시를 타기때문
            },
        });
    },

    /**
     * 다운로드 토큰 조회
     */
    getDownloadToken({counselMessageNo, documentNo, imageNos, serviceType = 'COUNSEL_MESSAGE'}) {
        return counselApi.get('educommon/api/multimedia/token', {
            params: {
                tokenType: 'DOWNLOAD',
                serviceNo: counselMessageNo,
                fileNos: documentNo,
                imageNos,
                serviceType,
                '_': new Date().getTime(), //IE 에서 토큰이 캐시를 타기때문
            },
        });
    },

    /**
     * 문서 업로드를 시작한다.
     * @param tokenResponse
     * @param file
     * @param progressCallback
     * @param requestCallback
     */
    startDocumentUpload({tokenResponse, file, progressCallback, requestCallback}) {
        const url = `${tokenResponse.serverUrl}/upload/document/multipart?token=${tokenResponse.token}`
            + `&serviceCode=${UPLOAD_SERVICE_CODE}&container=${UPLOAD_CONTAINER_NAME}`;

        const formData = new FormData();
        formData.append('file', file);

        return counselApi.post(url, formData, {
            onUploadProgress: progressCallback,
            cancelToken: new Axios.CancelToken(function executor(cancel) {
                requestCallback({cancel});
            }),
        });
    },

    /**
     * 이미지 업로드를 시작한다.
     * @param tokenResponse
     * @param file
     */
    startImageUpload({tokenResponse, file}) {
        const url = `${tokenResponse.serverUrl}/upload/image/multipart?token=${tokenResponse.token}&serviceCode=${UPLOAD_SERVICE_CODE}`
            + `&organizationNo=0&operationIds=&referenceServiceCode=${UPLOAD_IMAGE_COUNSEL_REFERENCE_SERVICE_CODE}`;

        const formData = new FormData();
        formData.append('file', file);

        return counselApi.post(url, formData);
    },

    /**
     * 전송 전 첨부한 문서 다운로드 위한 임시 매핑
     * @param objectStorageNo
     * @param key
     */
    mappingTempDocument({objectStorageNo, key}) {
        return counselApi.post(`educommon/api/external/documents/temporary`, {
            objectStorageNo,
            key,
        });
    },
};
