import Vue from 'vue';
import {$eventBus, EVENT_HIDE_LOADING, EVENT_SHOW_LOADING} from '@common/utils/eventUtil';
import {generatekey} from '@common/utils/key';

export const $loading = {
	show() {
		const key = generatekey({category: 'loading'});
		$eventBus.$emit(EVENT_SHOW_LOADING, {key});
		return key;
	},
	hide({key}) {
		$eventBus.$emit(EVENT_HIDE_LOADING, {key});
	},
};

Object.defineProperties(Vue.prototype, {
	$loading: {
		get() {
			return $loading;
		},
	},
});
